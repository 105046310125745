import { Box, Tooltip } from "@mui/material";

export default function ChannelTypes({
  activeChannelType,
  changeChannelType,
  unreadCount,
}: {
  activeChannelType: string;
  changeChannelType: (value: string) => void;
  unreadCount: number;
}) {
  const types = [
    {
      text: "My Channels",
      key: "my-channels",
      toolTip: "You are a member of these channels",
      indicator: false,
    },
    {
      text: "Unread",
      key: "unread-channels",
      toolTip: null,
      indicator: true,
    },
    {
      text: "All Channels",
      key: "all-channels",
      toolTip: null,
      indicator: false,
    },
  ].map((type) => ({
    ...type,
    active: type.key === activeChannelType,
  }));

  return (
    <Box
      component="ul"
      className="channel-types space-x-0.5"
      sx={{ display: "flex", width: "100%" }}
    >
      {types.map((type) => (
        <Tooltip title={type.toolTip} key={type.key}>
          <Box component="li">
            <button
              type="button"
              className={`${type.active ? "active" : ""}`}
              onClick={() => changeChannelType(type.key)}
            >
              <span>{type.text}</span>
              {type.indicator && unreadCount > 0 && (
                <span
                  className={`indicator ${
                    unreadCount > 0 ? "active" : "in-active"
                  }`}
                />
              )}
            </button>
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
}
