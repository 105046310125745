import NewWorkspaceChannelItem from "@/components/WorkspaceChannelList/WorkspaceChannelLinkItem";
import { FullInput } from "@/components/Utils";
import ChannelTypes from "@/components/WorkspaceChannelList/ChannelTypes";
import { useElectric } from "@/electric/ElectricWrapper";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import AddIcon from "@mui/icons-material/Add";
import { Box, useTheme } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useLiveQuery } from "electric-sql/react";
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom";
import CancelRounded from "@mui/icons-material/CancelRounded";
import SearchIcon from "@mui/icons-material/Search";
import type { Feed } from "@/generated/client";
import Locator from "@/locator";
import { UxContext } from "@/models/UxStateProvider";
import SearchFilter from "@/components/SearchFilter";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import WorkspaceGroup from "@/components/WorkspaceChannelList/WorkspaceGroup";
import ChannelEmptyState from "@/components/WorkspaceChannelList/ChannelEmptyState";

export interface channelItem extends Feed {
  groupCol: string;
  groupName: string;
  groupId: null | string;
  id: string;
  title: string;
  workspaceId: string;
  feedGroupId: string;
  createdAt: string;
  joined: string;
  aliasChannel: null | string;
  unread: number;
  unreadId: string;
  latestActivity: string;
  latestDriverMessage: string;
  channels?: channelItem[];
}

export default function WorkspaceChannelListContainer({
  notificationsBanner,
}: { notificationsBanner: boolean }) {
  const { toggleChannelModal, userReadOnlyMode, setWorkspaceModalOpen } =
    useContext(UxContext);
  const { isWorkspaceLimitedMember } = useContext(WorkspaceContext);
  const { workspaceMembershipId } = useContext(LiveQueryContext);
  const params = useParams();
  const { workspaceId, feedId } = params;
  const limitedMember = isWorkspaceLimitedMember();
  const { db } = useElectric();
  /**
   * Marks channels as keepAlive in local db, for when you visit the Unread Channels and it doesn't disappear after reading a message
   */
  const setLocalDBUnreadId = async (id: string | null) => {
    await db.item.updateMany({
      where: {
        keepAlive: 1,
      },
      data: {
        keepAlive: 0,
      },
    });
    if (id === null) {
      return;
    }

    const activeUnreadItem = await db.item.findFirst({
      where: {
        feedId: id,
        unread: 1,
      },
    });
    if (activeUnreadItem?.id) {
      await db.item.update({
        where: {
          id: activeUnreadItem.id,
        },
        data: {
          keepAlive: 1,
        },
      });
    }
  };

  const [channelOrder, saveChannelOrder] = useLocalStorage(
    "channel-sort-order",
    "driver-activity",
  );

  const [channelType, saveChannelType] = useLocalStorage(
    "channel-sort-type",
    "my-channels",
  );

  const [activeChannelType, setActiveChannelType] =
    useState<string>(channelType);

  const [searchValue, setSearchValue] = useState<string>("");

  const channelsQuery = useLiveQuery(() => {
    const latestActivity = `MAX(
					coalesce(feed.updatedAt, '0'),
					coalesce(MAX(item.createdAt), '0')
				)`;

    let orderDirection = "";
    if (channelOrder === "driver-activity") {
      orderDirection = `ORDER BY ${latestActivity} DESC, aliasChannel IS NULL, aliasChannel DESC`;
    } else if (channelOrder === "alpha-asc") {
      orderDirection = "ORDER BY lower(feed.title) ASC";
    }

    const selectPortion = `
		SELECT
        DISTINCT feed_group_membership.groupId as groupCol,
        feed_group.name as groupName,
				feed.id,
				feed.workspaceId,
				feed.*,
				COALESCE(feed.title, feed_group.name) as title,
        feed_group_membership.groupId as feedGroupId,
				item.createdAt,
        permission.workspace_membershipId as joined,
        EXISTS(select i.id from item as i where i.keepAlive = true AND feed.id = i.feedId LIMIT 1) as keepAlive,
        EXISTS(select i.id from item as i where i.unread = true AND feed.id = i.feedId LIMIT 1) as unread,
        (SELECT alias FROM workspace_command_alias WHERE workspace_command_alias.feedId = feed.id LIMIT 1) as aliasChannel,
        (select i.id from item as i where i.unread = true AND feed.id = i.feedId LIMIT 1) as unreadId,
				${latestActivity} as latestActivity,
        (SELECT MAX(item.createdAt)  FROM item  JOIN workspace_membership ON item.accountId = workspace_membership.accountId  WHERE item.feedId = feed.id  AND workspace_membership.role = 'limitedMember') as latestDriverMessage
			FROM feed
    `;

    const searchWhereClause =
      searchValue?.length > 0
        ? `AND (REPLACE(feed.title,' ','') like '%${searchValue}%' OR feed.title like '%${searchValue}%' OR feed.id LIKE '%${searchValue}%' OR feed_group.name LIKE '%${searchValue}%' OR REPLACE(feed_group.name,' ','') LIKE '%${searchValue}%')`
        : "";

    let additionalQuery = "";
    if (!limitedMember && channelType === "all-channels") {
      additionalQuery = `
      LEFT JOIN item 
            ON feed.id = item.feedId
          LEFT JOIN permission 
            ON feed.id = permission.feedId
            AND permission.workspace_membershipId = $1
            AND permission.name = 'read'
            AND permission.enabled = true
          LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
          LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
          WHERE
            feed.workspaceId = $2
            AND feed.isDm = 0 
            AND ((
              feed.isPrivate = 1
              AND permission.enabled = true
            ) OR (
              feed.isPrivate = 0
            ))
            ${searchWhereClause}
      `;
    } else if (channelType === "unread-channels") {
      additionalQuery = `
      JOIN item ON feed.id = item.feedId
      JOIN permission  ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
      LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
      LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
      WHERE
        feed.workspaceId = $2 AND feed.isDm = 0 AND (unread = 1 OR keepAlive = 1)
      ${searchWhereClause}
      `;
    } else {
      additionalQuery = `
    LEFT JOIN item ON feed.id = item.feedId
    JOIN permission ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
    LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
    LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
    WHERE
      feed.workspaceId = $2
      AND
      feed.isDm = 0 
    ${searchWhereClause}
      `;
    }

    const sql = `
    ${selectPortion}
    ${additionalQuery}
    GROUP BY feed.id
    ${orderDirection}
    `;

    const args = [workspaceMembershipId, workspaceId, workspaceId];

    return db.liveRaw({
      sql,
      args,
    });
  }, [
    channelType,
    channelOrder,
    searchValue,
    workspaceMembershipId,
    workspaceId,
  ]);

  const channels = useMemo(()=>{
    return channelsQuery?.results?.length
      ? channelsQuery?.results
      : []
  }, [channelsQuery?.results])  as channelItem[]

  const sortByType = (
    arrayOfObjects: channelItem[],
    type: string,
    order?: string,
  ) => {
    if (order === "asc") {
      return arrayOfObjects?.sort((a, b) => a.title?.localeCompare(b?.title));
    }

    if (order === "desc") {
      return arrayOfObjects?.sort((a, b) => b.title?.localeCompare(a?.title));
    }

    return arrayOfObjects.sort((a, b) =>
      b[type] > a[type] ? 1 : a[type] > b[type] ? -1 : 0,
    );
  };

  const mapGroupsAndChannels = (arrayOfObjects: channelItem[]) => {
    const groupsOfChannels = {
      groups: [],
      channels: [],
    };
    for (const channelItem of arrayOfObjects) {
      if (channelItem?.feedGroupId) {
        if (
          !groupsOfChannels?.groups.find(
            (group) => group.groupId === channelItem?.feedGroupId,
          )
        ) {
          groupsOfChannels.groups.push({
            groupId: channelItem.feedGroupId,
            groupName: channelItem.groupName,
            title: channelItem.groupName,
            latestActivity: channelItem.latestActivity,
            channels: [channelItem],
            unread: channelItem.unread,
          });
        } else {
          const index = groupsOfChannels.groups.findIndex(
            (group) => group.groupId === channelItem.feedGroupId,
          );
          if (groupsOfChannels?.groups[index]?.channels) {
            if (
              channelItem?.joined &&
              channelItem.unread === 1 &&
              groupsOfChannels?.groups[index]
            ) {
              groupsOfChannels.groups[index].unread += 1;
            }
            groupsOfChannels?.groups[index].channels.push(channelItem);
          }
        }
      } else {
        groupsOfChannels.channels.push(channelItem);
      }
    }

    const filterString = "latestActivity";
    const updatedGroupsByActivity = groupsOfChannels.groups.map((group) => {
      const channels =
        channelOrder === "alpha-asc"
          ? sortByType(group.channels, "title", "asc")
          : sortByType(group.channels, filterString);

      const findGroupById = groupsOfChannels.groups.find(
        (g) => g.groupId === group.groupId,
      );
      if (findGroupById && channels[0][filterString]) {
        findGroupById.latestActivity = channels[0][filterString];
      }
      return {
        ...group,
        channels,
      };
    });

    const flatList = [...updatedGroupsByActivity, ...groupsOfChannels.channels];

    return channelOrder === "driver-activity"
      ? sortByType(flatList, "latestActivity")
      : sortByType(flatList, "title", "asc");
  };

  const mappedChannels: channelItem[] =
    channels?.length > 0 ? mapGroupsAndChannels(channels) : [];

  const unreadCount = mappedChannels.reduce((accumulator, currentValue) => {
    if (currentValue.unread === 1 && (currentValue.joined || currentValue.groupId)) {
      return accumulator + 1;
    }
    return accumulator;
  }, 0);

  const [activeGroups, setActiveGroups] = useState<string[]>([]);
  const toggleGroup = (groupId: string) => {
    if (activeGroups.includes(groupId)) {
      setActiveGroups((prev) => prev.filter((group) => group !== groupId));
    } else {
      setActiveGroups((prev) => [...prev, groupId]);
    }
  };

  const [searchInputFullWidth, setSearchInputFullWidth] =
    useState<boolean>(false);

  const handleInputFocus = (value: boolean) => {
    if (value === true && !searchInputFullWidth) {
      setSearchInputFullWidth(() => true);
    } else if (value === false && searchValue.length === 0) {
      setSearchInputFullWidth(() => false);
    }
  };

  return (
    <Box
      className={`
        workspace-channel-list-container
        ${notificationsBanner ? "has-banner" : ""}
      `}
    >
      <Box
        className={`
            channel-search-container
            ${searchInputFullWidth ? "active" : ""}
        `}
      >
        <Box
          className={`channel-search ${limitedMember ? "limited-member" : ""}`}
        >
          <FullInput
            onFocus={() => handleInputFocus(true)}
            onBlur={() => handleInputFocus(false)}
            value={searchValue}
            inputProps={{
              placeholder: "Search"
            }}
            formControlProps={{
              sx: {
                "& .MuiInputBase-input": {
                  paddingLeft:'1rem !important'
                },
              },
            }}
            callback={(e) => {
              setSearchValue(e.target.value);
              setActiveChannelType(() => "all-channels");
              saveChannelType(()=>"all-channels");
            }}
          />
          <Box className="channel-search-clear-button">
            {searchValue?.length ? (
              <button
                type="button"
                aria-label={Locator.workspaceNav.channels.find.clear}
                onClick={() => {
                  setSearchValue(() => "");
                  setSearchInputFullWidth(() => false);
                }}
              >
                <CancelRounded />
              </button>
            ) : (
              <div>
                <SearchIcon
                  sx={{ color: searchValue?.length > 0 ? "#fff" : "#ccc" }}
                />
              </div>
            )}
          </Box>
        </Box>
        <Box
          className={`flex channel-options space-x-0.5 ${
            limitedMember ? "limited-member" : ""
          }`}
        >
          {!limitedMember && (
            <Box className="channel-options-button">
              <button
                type="button"
                onClick={toggleChannelModal}
                aria-label={Locator.workspaceNav.channels.create}
              >
                <AddIcon />
              </button>
            </Box>
          )}
          <Box className="channel-options-button">
            <SearchFilter
              channelOrder={channelOrder}
              changeChannelSortOrder={(data) => saveChannelOrder(data)}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb: 2, px: 1 }}>
        <ChannelTypes
          unreadCount={unreadCount}
          changeChannelType={(value) => {
            setLocalDBUnreadId(null);
            setActiveChannelType(() => value);
            saveChannelType(() => value);
          }}
          activeChannelType={activeChannelType}
        />
      </Box>
      <Box
        className="workspace-channel-list-items"
        aria-label={Locator.workspaceNav.channels.list.container}
      >
        {mappedChannels?.length > 0 ? (
          <>
            {mappedChannels?.map((channel: channelItem) => (
              <Box key={channel?.id ?? channel?.groupId}>
                {channel?.channels?.length > 0 ? (
                  <WorkspaceGroup
                    activeGroups={activeGroups}
                    group={channel}
                    feedId={feedId}
                    workspaceMembershipId={workspaceMembershipId}
                    toggleGroup={(data) => toggleGroup(data)}
                    setLocalDBUnreadId={(id) => setLocalDBUnreadId(id)}
                  />
                ) : (
                  <NewWorkspaceChannelItem
                    clickUnreadItem={(id: string | null) => setLocalDBUnreadId(id)}
                    active={channel.id === feedId}
                    joined={channel?.joined !== null}
                    channel={channel}
                    workspaceMembershipId={workspaceMembershipId}
                  />
                )}
              </Box>
            ))}
          </>
        ) : (
          <ChannelEmptyState
            hasSearch={searchValue?.length > 0}
            channelType={channelType}
            changeChannelType={(value) => {
              setActiveChannelType(value);
              saveChannelType(value);
            }}
          />
        )}
      </Box>
    </Box>
  );
}
