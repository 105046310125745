import type React from "react";
import { Box, MenuItem } from "@mui/material";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Locator from "@/locator";
import ListIcon from "@/components/Icons/ListIcon";
import { useElectric } from "@/electric/ElectricWrapper";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
export default function LinkContextMenu({
  channel,
  joined,
  workspaceMembershipId,
  handleClose,
  channelUrl,
}) {
  // @todo: implement tracking for joining channels via context menus
  // const { ampli } = useContext(TrackingContext);
  const { fetchNextActiveChannel } = useContext(LiveQueryContext);
  const { joinPublicChannel, leavePublicChannel } = useContext(DataContext);
  const { accountEvent } = useContext(ActionContext);
  const { db } = useElectric();
  const { workspaceId, feedId } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);

  const markChannelAsRead = async () => {
    accountEvent("Marked Feed as Read", {
      feedId: channel.id,
    });
    setDisabled(() => false);
    handleClose();
  };

  const joinChannel = async (id: string, redirect: boolean) => {
    setDisabled(() => true);
    await joinPublicChannel(workspaceId, id);
    handleClose();
    setDisabled(() => false);
    if (feedId !== id && redirect) {
      navigate(channelUrl);
    }
  };

  const leaveChannel = async () => {
    setDisabled(() => true);
    await leavePublicChannel(workspaceId, channel.id, workspaceMembershipId);
    const url = await fetchNextActiveChannel();
    handleClose();
    setDisabled(() => false);
    if (url && channel.id === feedId) {
      navigate(url);
    } else {
      navigate(`/workspaces/${workspaceId}`);
    }
  };

  const markAllAsRead = async () => {
    setDisabled(() => true);
    const feedItems = await db.item.findMany({
      where: {
        unread: 1,
      },
    });
    if (feedItems.length > 0) {
      for (const feedItem of feedItems) {
        accountEvent("Marked Feed as Read", {
          feedId: feedItem.feedId,
        });
      }
    }

    setDisabled(() => false);
    handleClose();
  };

  return (
    <div className="new-channel-item-context-menu">
      {channel?.unread === 1 && channel?.joined && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.markAsRead}
          disabled={disabled}
          onClick={markChannelAsRead}
        >
          <Box className="new-channel-context-button">
            <DraftsOutlinedIcon />
          </Box>
          <Box>Mark As Read</Box>
        </MenuItem>
      )}
      {channel?.unread === 1 && channel?.joined && (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.markAllAsRead}
          disabled={disabled}
          onClick={markAllAsRead}
        >
          <Box className="new-channel-context-button">
            <ListIcon />
          </Box>
          <Box>Mark all as Read</Box>
        </MenuItem>
      )}
      {joined ? (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.leave}
          disabled={disabled}
          onClick={leaveChannel}
        >
          <Box className="new-channel-context-button">
            <LogoutIcon />
          </Box>
          <Box>Leave Channel</Box>
        </MenuItem>
      ) : (
        <MenuItem
          aria-label={Locator.workspaceNav.channels.list.join}
          disabled={disabled}
          onClick={() => joinChannel(channel.id, false)}
        >
          <Box className="new-channel-context-button">
            <LoginIcon />
          </Box>
          <Box>Join Channel</Box>
        </MenuItem>
      )}
    </div>
  );
}
