import { Box, Chip, Collapse, useTheme } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Folder from "@/components/Icons/Folder";
import Locator from "@/locator";
import NewWorkspaceChannelItem from "@/components/WorkspaceChannelList/WorkspaceChannelLinkItem";
import type { channelItem } from "@/components/WorkspaceChannelList/WorkspaceChannelListContainer";
import UseContextMenu from "@/components/ContextMenus/UseContextMenu";
import React from "react";
import GroupContextMenu from "@/components/WorkspaceChannelList/GroupContextMenu";
import { useLiveQuery } from "electric-sql/react";
import { useElectric } from "@/electric/ElectricWrapper";

interface Props {
  activeGroups: string[];
  group: channelItem;
  toggleGroup: (groupId: string) => void;
  setLocalDBUnreadId: (groupId: string) => void;
  workspaceMembershipId: string;
  feedId: string;
}

export default function WorkspaceGroup({
  activeGroups,
  group,
  toggleGroup,
  setLocalDBUnreadId,
  workspaceMembershipId,
  feedId,
}: Props) {
  const { handleContextMenu, handleClose, contextMenuItem, contextMenu } =
    UseContextMenu();
  const theme = useTheme();
  const { db } = useElectric();

  const joinedChannels = group?.channels?.filter(
    (channel) => channel?.joined,
  )?.length;
  const joinedGroup = joinedChannels > 0;
  const groupHasUnreads =
    group?.channels?.filter((channel) => channel?.unread)?.length > 0;

  type GroupListType = {
    feedId: string;
    title: string;
    joined: boolean;
  };

  const listOfGroups = useLiveQuery(() => {
    if (!group.groupId || !workspaceMembershipId) {
      return;
    }
    const sql = `
    SELECT
       fgm.feedId,
       feed.title,
       EXISTS(
            SELECT perms.workspace_membershipId
            from permission as perms
            WHERE
                perms.enabled = true
                AND
                perms.name = 'read'
                AND
                perms.feedId = fgm.feedId
                AND
                perms.workspace_membershipId = $1
            LIMIT 1
       ) as joined
    FROM feed_group_membership as fgm
         JOIN feed ON feed.id = fgm.feedId
         JOIN permission ON permission.feedId = fgm.feedId
    WHERE
        fgm.groupId = $2
        AND
        permission.enabled = true
    GROUP by fgm.feedId
    `;

    return db.liveRaw({
      sql,
      args: [workspaceMembershipId, group.groupId],
    });
  }, [workspaceMembershipId, group.groupId]);

  const groupList = listOfGroups?.results as GroupListType[];
  const joinedAllChannels =
    groupList?.filter((channel) => channel.joined)?.length ===
    groupList?.length;
  const joinAllChannelList = joinedAllChannels
    ? false
    : groupList
        ?.filter((channel) => !channel.joined)
        ?.map((channel) => channel.feedId)?.length > 0;

  return (
    <Box
      data-testid={Locator.workspaceNav.channels.list.groups.testId(
        group.groupId,
      )}
      className={`
        group-container
        ${activeGroups?.includes(group.groupId) ? "active" : ""}
        ${joinedGroup ? "joined" : "not-joined"}
      `}
      onContextMenu={handleContextMenu}
    >
      <button
        type="button"
        className="group-button space-x-0.5"
        onClick={() => toggleGroup(group.groupId)}
      >
        <Box className="group-icons">
          <Box className="group-icon-arrow">
            {activeGroups?.includes(group.groupId) ? (
              <KeyboardArrowUp role="img" />
            ) : (
              <KeyboardArrowDown role="img" />
            )}
          </Box>
          <Box className="group-icon-folder">
            <Folder role="img" />
          </Box>
        </Box>

        <Box className="group-name">{group.groupName}</Box>
        {group?.unread > 0 && (
          <Chip
            aria-label={Locator.workspaceNav.channels.list.unreadCount}
            data-testid={Locator.workspaceNav.channels.list.groups.unreadCountId(
              group.unread.toString(),
            )}
            sx={{
              background: theme.palette.brand.secondary.main,
              width: 44,
              height: 28,
              fontSize: "1.125rem",
              fontWeight: 500,
              "& .MuiChip-label": {
                p: 0,
              },
              ml: "auto",
            }}
            label={group.unread}
          />
        )}
      </button>
      <Collapse in={activeGroups?.includes(group.groupId)}>
        <Box className="group-container-channels">
          {group?.channels?.map((subChannel) => (
            <NewWorkspaceChannelItem
              clickUnreadItem={(id: string | null) => {
                setLocalDBUnreadId(id);
              }}
              key={subChannel.id}
              active={subChannel.id === feedId}
              joined={subChannel?.joined !== null}
              channel={subChannel}
              workspaceMembershipId={workspaceMembershipId}
            />
          ))}
        </Box>
      </Collapse>

      {contextMenuItem(
        <GroupContextMenu
          group={group}
          joinAllChannelList={joinAllChannelList}
          joinedGroup={joinedGroup}
          groupHasUnreads={groupHasUnreads}
          handleClose={handleClose}
        />,
      )}
    </Box>
  );
}
